import { Flex, Image } from '@vtex/store-ui'
import React, { useContext } from 'react'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

interface OptionsType {
  claro: Array<{ img: string; value: string; subtone: string }>
  'medio-claro': Array<{ img: string; value: string; subtone: string }>
  medio: Array<{ img: string; value: string; subtone: string }>
  'medio-escuro': Array<{ img: string; value: string; subtone: string }>
  escuro: Array<{ img: string; value: string; subtone: string }>
}

const options: OptionsType = {
  claro: [
    { img: 'claro-frio-2.jpg', value: '110 F', subtone: 'Frio' },
    {
      img: 'claro-neutro-quente.jpg',
      value: '120 NQ',
      subtone: 'Neutro Quente',
    },
    { img: 'claro-frio.jpg', value: '130 F', subtone: 'Frio' },
    { img: 'claro-quente.jpg', value: '134 Q', subtone: 'Quente' },
    { img: 'medio-claro-quente.jpg', value: '210 Q', subtone: 'Quente' },
  ],
  'medio-claro': [
    { img: 'claro-quente.jpg', value: '134 Q', subtone: 'Quente' },
    { img: 'medio-claro-quente.jpg', value: '210 Q', subtone: 'Quente' },
    {
      img: 'medio-claro-neutro-quente.jpg',
      value: '220 NQ',
      subtone: 'Neutro Quente',
    },
    {
      img: 'medio-claro-neutro-frio.jpg',
      value: '230 NF',
      subtone: 'Neutro Frio',
    },
    { img: 'medio-claro-neutro.jpg', value: '240 N', subtone: 'Neutro' },
    { img: 'medio-neutro-frio.jpg', value: '310 NF', subtone: 'Neutro Frio' },
  ],
  medio: [
    { img: 'medio-claro-neutro.jpg', value: '240 N', subtone: 'Neutro' },
    { img: 'medio-neutro-frio.jpg', value: '310 NF', subtone: 'Neutro Frio' },
    { img: 'medio-quente-3.jpg', value: '320 Q', subtone: 'Quente' },
    { img: 'medio-quente-2.jpg', value: '330 Q', subtone: 'Quente' },
    { img: 'medio-quente.jpg', value: '340 Q', subtone: 'Quente' },
    {
      img: 'medio-escuro-neutro-quente.jpg',
      value: '410 NQ',
      subtone: 'Neutro Quente',
    },
  ],
  'medio-escuro': [
    { img: 'medio-quente.jpg', value: '340 Q', subtone: 'Quente' },
    {
      img: 'medio-escuro-neutro-quente.jpg',
      value: '410 NQ',
      subtone: 'Neutro Quente',
    },
    { img: 'medio-escuro-quente.jpg', value: '420 Q', subtone: 'Quente' },
    { img: 'medio-escuro-frio.jpg', value: '430 F', subtone: 'Frio' },
    {
      img: 'medio-escuro-neutro-frio.jpg',
      value: '440 NF',
      subtone: 'Neutro Frio',
    },
    { img: 'escuro-neutro-2.jpg', value: '510 N', subtone: 'Neutro' },
  ],
  escuro: [
    {
      img: 'medio-escuro-neutro-frio.jpg',
      value: '440 NF',
      subtone: 'Neutro Frio',
    },
    { img: 'escuro-neutro-2.jpg', value: '510 N', subtone: 'Neutro' },
    {
      img: 'escuro-neutro-quente.jpg',
      value: '520 NQ',
      subtone: 'Neutro Quente',
    },
    { img: 'escuro-neutro.jpg', value: '530 N', subtone: 'Neutro' },
    { img: 'escuro-frio.jpg', value: '540 F', subtone: 'Frio' },
  ],
}

const SkinColor = () => {
  const {
    setAnswers,
    nextQuestion,
    answers: { skinTone },
  } = useContext(EncontreSuaCorContext)

  const handleClick = (skinColor: string) => {
    setAnswers((state) => ({ ...state, skinColor }))
    nextQuestion()
  }

  return (
    <Flex sx={styles.container}>
      <span>Passo 3 de 6</span>
      <h2>Com qual cor de pele você mais se identifica?</h2>
      <Flex sx={styles.optionsContainer}>
        {options[skinTone].map(({ subtone, img, value }) => (
          <Image
            key={value}
            src={`/arquivos/${img}`}
            alt={subtone}
            title={subtone}
            onClick={() => handleClick(value)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default SkinColor
