import React, { useContext } from 'react'
import { Box, Flex, Image, Text } from '@vtex/store-ui'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

const options = [
  { value: 'oleosa', label: 'Oleosa' },
  { value: 'seca', label: 'Seca' },
  { value: 'mista', label: 'Mista' },
  { value: 'nao-sei', label: 'Não sei' },
]

const SkinType = () => {
  const { setAnswers, nextQuestion } = useContext(EncontreSuaCorContext)

  const handleClick = (skinType: string) => {
    setAnswers((state) => ({ ...state, skinType }))
    nextQuestion()
  }

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Image src="/arquivos/ids/176576/1245637_1.png" alt="Banner 05" />
      </Box>
      <Box sx={styles.optionsContainer}>
        <span>Passo 5 de 6</span>
        <h2>Qual é o seu tipo de pele?</h2>
        <Flex>
          {options.map(({ label, value }) => (
            <Text
              as="p"
              role="button"
              key={value}
              onClick={() => handleClick(value)}
            >
              {label}
            </Text>
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}

export default SkinType
