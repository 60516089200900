import React, { useContext } from 'react'
import { SuspenseSSR } from '@vtex/store-ui'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'

import { EncontreSuaCorContext } from '../../components/encontresuacor/context'
import EmailQuestion from '../../components/encontresuacor/Questions/EmailQuestion'
import Header from '../../components/encontresuacor/Questions/Header'
import SkinTone from '../../components/encontresuacor/Questions/SkinTone'
import SkinColor from '../../components/encontresuacor/Questions/SkinColor'
import Jewel from '../../components/encontresuacor/Questions/Jewel'
import SkinType from '../../components/encontresuacor/Questions/SkinType'
import Makeup from '../../components/encontresuacor/Questions/Makeup'

const EncontreSuaCor = () => {
  const { step } = useContext(EncontreSuaCorContext)

  const profile = useProfile()
  const { setIsLoginModalOpen } = useLoginModal()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  if (!isAuthenticated) {
    setIsLoginModalOpen(true)
  }

  return (
    <>
      <Header />
      {step === 1 && (
        <SuspenseSSR fallback={null}>
          <EmailQuestion />
        </SuspenseSSR>
      )}
      {step === 2 && <SkinTone />}
      {step === 3 && <SkinColor />}
      {step === 4 && <Jewel />}
      {step === 5 && <SkinType />}
      {step === 6 && <Makeup />}
    </>
  )
}

export default EncontreSuaCor
