import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Image, Input } from '@vtex/store-ui'
import type { FormEvent } from 'react'
import { isValidEmail } from 'src/utils'
import { useToast, useProfile } from '@vtex/gatsby-theme-store'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

const EmailQuestion = () => {
  const { setAnswers, setDocumentId, nextQuestion } = useContext(
    EncontreSuaCorContext
  )

  const [emailUserActive, setEmailUserActive] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const inputEmailRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = profile?.email?.value
      const requestUrl = `/api/io/safedata/CL/search?_where=email=${userEmail}&_fields=id`
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }

      if (userEmail && inputEmailRef.current) {
        inputEmailRef.current.value = userEmail
        setEmailUserActive(true)
        setEmail(userEmail)

        fetch(requestUrl, options)
          .then((res) => res.json())
          .then((data: Array<{ id: string }>) => {
            setDocumentId(data[0].id)
          })
          .catch((error) => console.error('Error while get data', error))
      }
    }
  }, [isAuthenticated, profile?.email?.value, setDocumentId])

  const { showToast } = useToast()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (isValidEmail(email)) {
      setAnswers((state) => ({ ...state, email, name }))

      nextQuestion()
    }

    if (!isValidEmail(email)) {
      showToast({
        content: 'Email inválido. Digite um email válido',
        type: 'error',
        id: 'mobile-add-to-cart',
      })
    }
  }

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Image src="/arquivos/ids/176998/Seu%20nome.png" alt="Banner 01" />
      </Box>
      <Box sx={styles.formContainer}>
        <span>Passo 1 de 6</span>
        <h2>Qual o seu nome?</h2>
        <Box as="form" onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={inputEmailRef}
            disabled={!!emailUserActive}
            required
          />
          <Input
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Button>Próximo</Button>
        </Box>
      </Box>
    </Flex>
  )
}

export default EmailQuestion
