import React, { useContext } from 'react'
import { Flex, Image } from '@vtex/store-ui'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

const options: Array<{
  image: string
  title: string
  type: 'claro' | 'medio-claro' | 'medio' | 'medio-escuro' | 'escuro'
}> = [
  {
    type: 'claro',
    title: 'Claro',
    image: '/arquivos/claro-passo2.jpg',
  },
  {
    type: 'medio-claro',
    title: 'Médio claro',
    image: '/arquivos/medio-claro-passo2.jpg',
  },
  {
    type: 'medio',
    title: 'Médio',
    image: '/arquivos/medio-passo2.jpg',
  },
  {
    type: 'medio-escuro',
    title: 'Médio escuro',
    image: '/arquivos/medio-escuro-passo2.jpg',
  },
  {
    type: 'escuro',
    title: 'Escuro',
    image: '/arquivos/escuro-passo2.jpg',
  },
]

const SkinTone = () => {
  const { setAnswers, nextQuestion } = useContext(EncontreSuaCorContext)

  const handleClick = (
    skinTone: 'claro' | 'medio-claro' | 'medio' | 'medio-escuro' | 'escuro'
  ) => {
    setAnswers((state) => ({ ...state, skinTone }))

    nextQuestion()
  }

  return (
    <Flex sx={styles.container}>
      <span>Passo 2 de 6</span>
      <h2>Qual é o seu tom de pele?</h2>
      <Flex sx={styles.optionsContainer}>
        {options.map(({ image, type, title }) => (
          <Image
            key={type}
            src={image}
            alt={type}
            title={title}
            onClick={() => handleClick(type)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default SkinTone
