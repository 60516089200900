import React, { useContext, useState } from 'react'
import { Box, Button, Flex, Image, Text } from '@vtex/store-ui'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

const options = [
  { value: 'efeitoMatte', label: 'Efeito Matte' },
  { value: 'efeitoNatural', label: 'Efeito Natural' },
  { value: 'efeitoRadiante', label: 'Efeito Radiante' },
]

const Makeup = () => {
  const { setAnswers, nextQuestion } = useContext(EncontreSuaCorContext)
  const [makeup, setMakeup] = useState('')

  const handleClick = () => {
    setAnswers((state) => ({ ...state, makeup }))
    nextQuestion()
  }

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Image src="/arquivos/ids/176577/RETOCADA.png" alt="Banner 06" />
      </Box>
      <Box sx={styles.optionsContainer}>
        <span>Passo 6 de 6</span>
        <h2>Que acabamento de base você prefere?</h2>
        <Flex>
          {options.map(({ label, value }) => (
            <Text
              as="p"
              role="button"
              key={value}
              onClick={() => setMakeup(value)}
              sx={{
                backgroundColor: [value === makeup ? '#5dc3b6' : ''],
                color: [value === makeup ? '#FFF' : ''],
                borderColor: [value === makeup ? 'transparent' : ''],
              }}
            >
              {label}
            </Text>
          ))}
        </Flex>
        <Button onClick={handleClick}>Veja o resultado</Button>
      </Box>
    </Flex>
  )
}

export default Makeup
