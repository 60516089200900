import React from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import EncontreSuaCor from 'src/views/encontresuacor'
import { EncontreSuaCorContextProvider } from 'src/components/encontresuacor/context'
import { SuspenseSSR } from '@vtex/store-ui'
import Layout from 'src/components/Layout'

const Page: FC = () => {
  return (
    <Layout hideHeaderAndFooter>
      <Helmet>
        <title>Escolha Sua Cor! - Quiz Base</title>
      </Helmet>
      <EncontreSuaCorContextProvider>
        <SuspenseSSR fallback={null}>
          <EncontreSuaCor />
        </SuspenseSSR>
      </EncontreSuaCorContextProvider>
    </Layout>
  )
}

export default Page
