import React, { createContext, useState } from 'react'
import type { ReactNode } from 'react'
import { navigate } from 'gatsby'

interface Answers {
  email: string
  name: string
  skinTone: string
  skinColor: string
  jewel: string
  skinType: string
  makeup: string
}

interface IQuizContext {
  step: number
  answers: Answers
  documentId: string
  nextQuestion: () => void
  backQuestion: () => void
  setAnswers: React.Dispatch<React.SetStateAction<Answers>>
  setDocumentId: React.Dispatch<React.SetStateAction<string>>
}

export const EncontreSuaCorContext = createContext({} as IQuizContext)

export const EncontreSuaCorContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [step, setStep] = useState(1)
  const [documentId, setDocumentId] = useState('')
  const [answers, setAnswers] = useState<Answers>({
    email: 'sd@asd.com',
    jewel: 'joias-acessorios-dourados-e-prateadas',
    makeup: 'efeitoNatural',
    name: 'teste',
    skinColor: '120 NQ',
    skinTone: 'claro',
    skinType: 'oleosa',
  })

  const nextQuestion = () => {
    if (step === 6) {
      const { skinTone, skinColor, jewel, skinType, makeup } = answers
      const answerParams = `tomDePele:${skinTone}|seIdentificaCom:${skinColor}|joiaMetal:${jewel}|tipoDePele:${skinType}|efeitoIdeal:${makeup}`

      return navigate(`/encontresuacor/resultado?answers=${answerParams}`)
    }

    return setStep(step + 1)
  }

  const backQuestion = () => {
    if (step > 1) {
      setStep(step - 1)
    }

    if (step === 1) {
      navigate('/')
    }
  }

  return (
    <EncontreSuaCorContext.Provider
      value={{
        step,
        nextQuestion,
        backQuestion,
        answers,
        setAnswers,
        documentId,
        setDocumentId,
      }}
    >
      {children}
    </EncontreSuaCorContext.Provider>
  )
}
