import React, { useContext } from 'react'
import { Box, Flex, Image, Text } from '@vtex/store-ui'

import { EncontreSuaCorContext } from '../../context'
import styles from './styles.json'

const options = [
  { value: 'joias-acessorios-dourados', label: 'Joias e acessórios dourados' },
  {
    value: 'joias-acessorios-prateados',
    label: 'Joias e acessórios prateados',
  },
  {
    value: 'joias-acessorios-dourados-e-prateadas',
    label: 'Fico bem tanto com joias douradas quanto prateadas',
  },
  { value: 'nao-sei', label: 'Não sei' },
]

const Jewel = () => {
  const { setAnswers, nextQuestion } = useContext(EncontreSuaCorContext)

  const handleClick = (jewel: string) => {
    setAnswers((state) => ({ ...state, jewel }))
    nextQuestion()
  }

  return (
    <Flex sx={styles.container}>
      <Box sx={styles.imageContainer}>
        <Image src="/arquivos/Joia.png" alt="Banner 03" />
      </Box>
      <Box sx={styles.optionsContainer}>
        <span>Passo 4 de 6</span>
        <h2>Que tipo de joia/metal fica melhor em você?</h2>
        <Flex>
          {options.map(({ label, value }) => (
            <Text
              as="p"
              role="button"
              key={value}
              onClick={() => handleClick(value)}
            >
              {label}
            </Text>
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}

export default Jewel
