import React, { useContext } from 'react'
import { Flex } from '@vtex/store-ui'

import styles from './styles.json'
import { EncontreSuaCorContext } from '../../context'

const Header = () => {
  const { backQuestion, step } = useContext(EncontreSuaCorContext)

  return (
    <Flex
      as="header"
      sx={{
        ...styles.container,
        '&::before': {
          content: "''",
          position: 'absolute',
          top: '47px',
          left: '0',
          width: `calc(14.2% * ${step})`,
          height: '3px',
          backgroundColor: '#f39ac1',
          transition: 'width 0.5s ease',
        },
      }}
    >
      <Flex
        sx={{
          ...styles.backButton,
          width: ['5rem', step === 1 ? '9rem' : '5rem'],
        }}
        onClick={backQuestion}
      >
        <svg
          version="1.1"
          id="Camada_1_arrow-right"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 19.6 31.7"
          xmlSpace="preserve"
        >
          <g transform="translate(7.41) rotate(90)">
            <path d="M28,7.3l-12-12l-12,12L0.2,3.6l15.7-15.7L31.7,3.6L28,7.3z" />
          </g>
        </svg>
        <span>{step === 1 ? 'Ir para Home' : 'Voltar'}</span>
      </Flex>
      <h1>Base Perfeita</h1>
    </Flex>
  )
}

export default Header
